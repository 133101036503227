.calenderEntry {
    height: 5rem;
    width: 10rem;
    background-color: rgba(0, 0, 0, 0.6);
    margin: .5rem;
    padding: .8rem;
    line-height: 1rem;
    border-radius: 20px;
    background-color: rgba(255, 0, 0, 0.3);

}
.calenderEntry p {
    margin: 0;
    margin-top: .3rem;
}