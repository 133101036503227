.currentlyFree{
    background-color: rgba(0, 100, 0, 0.6);
    font-size: 1.1rem;
    padding: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 20px;
}

.currentlyFree p{
    margin-left: 2rem;
}

.currentlyFree h1{
    margin-top: 0;
}

.eventDetails{
    display: grid;
    grid-template-columns: 6rem 10rem;
    line-height: 1rem;
}

.adHocBooking{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.adHocBooking button{
    width: 5rem;
    padding: 1rem;
    background-color: rgba(0, 0, 0, .7);
    box-shadow: none;
    color: white;
    border-radius: 10px;
    border: none;
    font-weight: bold;
}


.currentlyFree h3{
    padding-left: 0;
    margin-left: 0;
}