.totalFrame{
    background-color: rgba(0, 0, 0, .7);
    border-radius: 20px;
    color: white;
    font-weight: bold;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 100px;
    margin-top: 100px;
    height: 70vh;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: auto;
    gap: 2rem;
    position: relative;

}

.totalFrame h1, h3{
    margin-left: 2rem;
}

.bookingFrame{
    overflow: clip;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    flex-grow: initial;
}

.rightPanel {
    max-width: 95%;
}

.leftPanel {
}

.logo{
    height: 2.5rem;
    position: absolute;
    bottom: 0.5rem;
    right: 1.5rem;

}

@media screen and (max-width: 1000px) {
    .totalFrame{
        grid-template-columns: auto;
        grid-template-rows: 50% 50%;
    }

    .rightPanel{
        margin-left: 1.5rem;
    }
}