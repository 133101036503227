.currentEvent{
    background-color: rgba(255, 0, 0, 0.3);
    font-size: 1.1rem;
    padding: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 20px;
}

.currentEvent p{
    margin-left: 2rem;
}

.currentEvent h1{
    margin-top: 0;
}

.eventDetails{
    display: grid;
    grid-template-columns: 6rem 10rem;
    line-height: 1rem;
}